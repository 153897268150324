<template>
	<div class="Trend bg-white">
		<Row class="px-1">
			<i-col span="19" class="align-justify">
				<Select v-model="userSelect" filterable size="large" style="width: 14rem; margin-right: 0.5rem;" placeholder="选择被测">
					<Option v-for="(uitem,ukey) in userOption" :key="ukey" :value="uitem.userId">{{uitem.userId}}&nbsp;&nbsp;{{uitem.userName}}</Option>
				</Select>
				<Select v-model="scaleSelect" filterable size="large" style="width: 14rem; margin-right: 0.5rem;" placeholder="选择量表">
					<Option v-for="(sitem,skey) in scaleOption" :key="skey" :value="sitem.key">{{sitem.label}}</Option>
				</Select>
				<DatePicker v-model="dateRange" size="large" type="daterange" :start-date="new Date()" separator="至" format="yyyy-MM-dd" style="width: 13rem;margin-right: 0.5rem;"></DatePicker>
				<Button type="primary" size="large" class="mr-1" @click="trendAnalyse()">分析</Button>
			</i-col>
			<i-col span="5" class="align-right">
				<Dropdown placement="bottom-end">
					<Button size="large">打印<Icon type="md-more"></Icon></Button>
					<DropdownMenu slot="list">
						<DropdownItem>趋势报告</DropdownItem>
						<!--数据表格和趋势图，包含各因子的初值、终值、总次数、平均间隔（间隔多少天重测一次）、趋好次数（比初值好）-->
						<DropdownItem>数据清单</DropdownItem>
						<!-- 以因子为行，日期为列的数据表格，每个表格8列，因子1列，7个日期7列，多于7个日期用续表-->
					</DropdownMenu>
				</Dropdown>
				
			</i-col>
			<i-col span="10" class="pr-1">
				<Table border stripe ref="dossiersTable" :columns="tColumns" :data="screenData" :height="theight" class="mt-1">

				</Table>
				<Page :total="recordTotal" :page-size="20" show-total show-elevator class="p-1 align-right" />
			</i-col>
			<i-col span="14" :style="{'height':theight + 'px'}" class="trendLine">
				<ve-line :data="trendLineChart" :settings="trengChartSet" :judge-width="true" :extend="extend" :grid="chartGrid" :legend="trengLegend" :data-empty="dataEmpty" height="100%"></ve-line>
			</i-col>
		</Row>
	</div>
</template>

<script>
	export default {
		name: 'Trend',
		data(){
			return {
				theight: window.innerHeight - 275,
				userSelect: '',
				userOption: [],
				scaleSelect: '',
				scaleOption: [{
					key:1,
					label:'儿童自我意识量表'
				}],
				tData: [],
				startDate: '',
				endDate: '',
				screenData: [],
				recordTotal: 10,
				tColumns: [{
					type: 'selection',
					width: 35,
					align: 'center'
				},{
					type: 'index',
					title:'序号',
					width: 45,
					align: 'center'
				}, {
					title: '被测号',
					key: 'userId',
					minWidth: 120
				}, {
					title: '姓名',
					key: 'userName',
					minWidth: 120
				}, {
					title: '测评时间',
					key: 'submitTime',
					minWidth: 100,
					render: (h, params) => {
						let dt = this.myjs.subDate(params.row.submitTime);
						return h('span', dt)
					},
					sortable: true,
					sortType: 'desc'
				}],
				// 无数据提示
				dataEmpty:false,
				// 横轴旋转
				extend:{
					'xAxis.0.axisLabel.rotate': 45
				},
				// 绘图区域
				chartGrid:{
					bottom: 16
				},
				trengLegend:{
					top:16
				},
				trengChartSet: {
					// 设置别名
					labelMap: {
						'F1':'抑郁',
						'F2':'焦虑',
						'F3':'强迫',
						'F4':'偏执',
						'F5':'敌对'
					},
					// 数据区间
					min:[1],
					max:[5]
				},
				trendLineChart: {
					// 数据列
					columns: ['date','F1','F2','F3','F4','F5'],
					// 数据
					rows: [
						// 时间必须放在第一项
						{'date':'10-11','F1':2.21,'F2':2.56,'F3':1.45,'F4':3.45,'F5':2.19},
						{'date':'10-18','F1':2.15,'F2':2.36,'F3':3.02,'F4':3.02,'F5':1.86},
						{'date':'10-25','F1':2.29,'F2':2.98,'F3':1.35,'F4':2.75,'F5':1.73},
						{'date':'11-02','F1':2.63,'F2':1.80,'F3':1.17,'F4':2.52,'F5':1.62},
						{'date':'11-09','F1':1.65,'F2':1.54,'F3':1.35,'F4':2.28,'F5':1.76},
						{'date':'11-16','F1':1.38,'F2':1.36,'F3':1.55,'F4':2.36,'F5':1.88},
						{'date':'11-23','F1':1.23,'F2':1.27,'F3':1.23,'F4':1.63,'F5':1.42}
					]
				}
			}
		},
		mounted() {
			window.addEventListener('resize', () => this.theight = window.innerHeight - 275, false)
		},
		methods:{
			// 选择测试者、量表和时间区间前提下，分析数据，设置别名、数据区间、数据列、数据
			trendAnalyse(){
				
			}
		}
	}
</script>

<style>
	.trendLine{
		border:1px solid #dcdee2;
		margin-top:0.5rem
	}
</style>
